export default class ClickableRowHandler {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.initializeClickableRows();
    });
  }

  initializeClickableRows() {
    document.querySelectorAll('table tr[data-href]').forEach(row => {
      row.addEventListener('click', (e) => {
        window.location.href = e.target.parentElement.dataset.href;
      });
    });
  }
}
